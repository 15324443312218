import React, { useContext, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import jwt from "jwt-decode";

import LoginScreen from "./components/LoginScreen";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Passwords from "./components/Passwords";
import Backup from "./components/Backup";
import NotFound from "./components/NotFound";

import { localContext } from "./Context";

export default function App() {
  const { ws, setLoggedInUser } = useContext(localContext);
  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
    // eslint-disable-next-line
  }, []);

  const loadInput = ({ data }) => {
    const { token } = JSON.parse(data);

    if (token.toString() === "deleted") {
      localStorage.clear();
      setLoggedInUser({});
      history.push("/");
      return;
    }

    const { uid, roomID, name, username, email, password, accounts } = JSON
      .parse(
        jwt(token).iss,
      );
    setLoggedInUser(
      {
        uid,
        roomID,
        name,
        username,
        email,
        password,
        accounts,
      },
    );
  };

  ws.addEventListener("message", loadInput);

  return (
    <div>
      <Switch>
        <Route exact path="/"><LoginScreen /></Route>
        <Route exact path="/dashboard"><Dashboard /></Route>
        <Route exact path="/profile"><Profile /></Route>
        <Route exact path="/passwords"><Passwords /></Route>
        <Route exact path="/backup"><Backup /></Route>
        <Route><NotFound /></Route>
      </Switch>
    </div>
  );
}
