import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { v1 as uuidv1 } from "uuid";
import axios from "axios";
//import jwt from "jwt-decode";

import { localContext } from "../Context";

export default function Passwords() {
  const { loggedInUser, setLoggedInUser, backendURL, ws } = useContext(
    localContext,
  );
  const history = useHistory();

  const [a_ID, a_setID] = useState("");
  const [a_Name, a_setName] = useState("");
  const [a_Category, a_setCategory] = useState("");

  const [u_Name, u_setName] = useState("");
  const [u_Username, u_setUsername] = useState("");
  const [u_Email, u_setEmail] = useState("");
  const [u_Password, u_setPassword] = useState("");
  const [u_Link, u_setLink] = useState("");

  const [genPass, setGenPass] = useState("");

  const [view, setView] = useState("l");
  const [message, setMessage] = useState("");

  function makeField(field, value, id) {
    if (field.toLowerCase() === "link") return null;

    return (
      <div
        className="w-full flex text-left items-center sm:mx-0 mx-auto"
        key={id}
      >
        <div
          className="text-blue-300 font-mono font-bold tracking-wider sm:w-1/3 w-1/4 uppercase sm:text-lg text-xs mr-1"
        >
          {field}
        </div>
        <div className="w-2 h-4 border-l-2 border-blue-300 mr-1"></div>
        <input
          className="text-blue-200 font-mono font-bold sm:text-lg text-xs bg-custom-300 sm:w-3/5 w-4/5"
          readOnly
          onClick={(e) => {
            e.target.select();
            document.execCommand("copy");
          }}
          value={value}
        />
      </div>
    );
  }

  function makeInputField(field, value, func, placeholder) {
    return (
      <div className="w-full flex text-left items-center">
        <div
          className="text-blue-300 font-mono font-bold tracking-wider sm:w-1/3 w-1/4 uppercase sm:text-lg text-xs mr-1"
        >
          {field}
        </div>
        <div className="w-2 h-4 border-l-2 border-blue-300 mr-1"></div>
        <input
          className="text-blue-600 font-mono font-bold sm:text-lg text-xs px-2 py-1 my-1 w-3/5 rounded border-4 border-blue-300 hover:border-blue-900 focus:border-blue-900 mx-auto placeholder-opacity-25 placeholder-blue-600"
          placeholder={placeholder}
          value={value}
          onChange={(e) => func(e.target.value)}
        />
      </div>
    );
  }

  function createAccount(name, category, link, accID, fields) {
    return (
      <div
        className="sm:w-11/12 w-full rounded-lg bg-gray-700 mt-4 border-2 border-blue-300 flex flex-col text-left"
        key={`account-${accID}`}
      >
        <div className="w-full p-2 pb-1 flex text-left justify-between">
          <p
            className="font-nunito text-blue-200 sm:text-xl text-lg font-extrabold tracking-wide underline sm:w-1/3 w-3/5"
          >
            {name}
          </p>
          <p
            className="rounded-lg font-nanum tracking-wide bg-orange-300 font-extrabold py-1 px-2 uppercase sm:w-1/6 sm:text-md text-xs text-custom-500 flex justify-center items-center sm:w-1/4 w-2/5"
          >
            {category}
          </p>
        </div>
        <div
          className="w-full p-2 flex flex-col text-left justify-left mt-1 border-t-2 border-blue-300"
        >
          {fields[0].map((item, index) =>
            makeField(item.field, item.value, item.id + (index * 13).toString())
          )}
          <div
            className="w-full flex sm:flex-row flex-col text-left mt-2 justify-between"
          >
            <a
              href={link.includes("http") ? link : "/"}
              target={link.includes("http") ? "_blank" : ""}
              className={`rounded p-1 bg-blue-300 ${
                link.includes("http")
                  ? `hover:bg-blue-400 focus:bg-blue-400`
                  : `opacity-50`
              } font-mono font-bold font-mono uppercase sm:w-1/4 w-4/5 sm:mx-0 mx-auto text-center text-blue-900 sm:mt-2 sm:text-lg text-xs`}
              rel="noopener noreferrer"
              disabled={!link.includes("http")}
            >
              Open
            </a>
            <button
              className="rounded p-1 bg-green-300 hover:bg-green-400 focus:bg-green-400 font-mono font-bold font-mono uppercase sm:w-1/4 w-4/5 sm:mx-0 mx-auto text-center text-blue-900 mt-2 sm:text-lg text-xs"
              onClick={() => prepareUpdate(accID)}
            >
              Edit
            </button>
            <button
              className="rounded p-1 bg-red-300 hover:bg-red-400 focus:bg-red-400 font-mono font-bold font-mono uppercase sm:w-1/4 w-4/5 sm:mx-0 mx-auto text-center text-blue-900 mt-2 sm:text-lg text-xs"
              onClick={() => submitDel(accID)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  }

  function prepareUpdate(id) {
    setView("e");
    const data = loggedInUser.accounts.find((account) => account.accID === id);

    a_setID(id);
    a_setName(data.name);
    a_setCategory(data.category);

    u_setName(data.fields[0].value);
    u_setUsername(data.fields[1].value);
    u_setEmail(data.fields[2].value);
    u_setPassword(data.fields[3].value);
    u_setLink(data.fields[4].value);
  }

  if (!loggedInUser.username) {
    let localMessage =
      "You need to register or log in first to view/edit/add accounts.";
    setTimeout(() => {
      history.push("/");
    }, 1000);

    return (
      <div
        className="w-full h-screen my-auto flex sm:flex-row flex-col items-center justify-around text-yellow-300 tracking-wide font-nunito sm:text-2xl text-lg font-bold mx-2 text-center"
      >
        {localMessage}
      </div>
    );
  }

  const submitAdd = () => {
    if (!a_Name || !a_Category) return;

    setMessage("Adding...");
    const generatedID = uuidv1().toString();

    const data = {
      "name": loggedInUser.name,
      "username": loggedInUser.username,
      "email": loggedInUser.email,
      "password": loggedInUser.password,

      "accID": generatedID,
      "accName": a_Name,
      "accCategory": a_Category,
      "accFields": [
        { "name": "Name", "value": u_Name },
        { "name": "Username", "value": u_Username },
        { "name": "Email", "value": u_Email },
        { "name": "Password", "value": u_Password },
        { "name": "Link", "value": u_Link },
      ],
    };

    axios.post(`${backendURL}/api/accounts/add`, { ...data })
      .catch((err) => console.log(err))
      .then(() => {
        ws.send(
          JSON.stringify(
            {
              email: data.email,
              password: data.password,
              roomID: loggedInUser.roomID,
              type: "change",
            },
          ),
        );
        setMessage("");
        const dataToSet = {
          "uid": loggedInUser.uid,
          "roomID": loggedInUser.roomID,
          "name": loggedInUser.name,
          "username": loggedInUser.username,
          "email": loggedInUser.email,
          "password": loggedInUser.password,

          "accounts": [
            ...loggedInUser.accounts,
            {
              "accID": generatedID,
              "name": a_Name,
              "category": a_Category,
              "fields": [
                { "name": "Name", "value": u_Name },
                { "name": "Username", "value": u_Username },
                { "name": "Email", "value": u_Email },
                { "name": "Password", "value": u_Password },
                { "name": "Link", "value": u_Link },
              ],
            },
          ],
        };
        setLoggedInUser(dataToSet);
        //localStorage.setItem("userdata", JSON.stringify({ username: dataToSet.username, password: dataToSet.password }));
        cancel();
      });
  };

  const submitEdit = () => {
    if (!a_Name || !a_Category) return;

    setMessage("Editing...");

    const data = {
      "name": loggedInUser.name,
      "username": loggedInUser.username,
      "email": loggedInUser.email,
      "password": loggedInUser.password,

      "accID": a_ID,
      "accName": a_Name,
      "accCategory": a_Category,
      "accFields": [
        { "name": "Name", "value": u_Name },
        { "name": "Username", "value": u_Username },
        { "name": "Email", "value": u_Email },
        { "name": "Password", "value": u_Password },
        { "name": "Link", "value": u_Link },
      ],
    };

    axios.put(`${backendURL}/api/accounts/update`, { ...data })
      .catch((err) => console.log(err))
      .then(() => {
        ws.send(
          JSON.stringify(
            {
              email: data.email,
              password: data.password,
              roomID: loggedInUser.roomID,
              type: "change",
            },
          ),
        );
        setMessage("");
        const dataToSet = {
          "uid": loggedInUser.uid,
          "roomID": loggedInUser.roomID,
          "name": loggedInUser.name,
          "username": loggedInUser.username,
          "email": loggedInUser.email,
          "password": loggedInUser.password,

          "accounts": loggedInUser.accounts.map((account) => {
            if (account.accID === a_ID) {
              return {
                "accID": a_ID,
                "name": a_Name,
                "category": a_Category,
                "fields": [
                  { "name": "Name", "value": u_Name },
                  { "name": "Username", "value": u_Username },
                  { "name": "Email", "value": u_Email },
                  { "name": "Password", "value": u_Password },
                  { "name": "Link", "value": u_Link },
                ],
              };
            } else {
              return account;
            }
          }),
        };
        setLoggedInUser(dataToSet);
        cancel();
      });
  };

  const submitDel = (id) => {
    setMessage("Deleting...");
    const data = {
      "username": loggedInUser.username,
      "email": loggedInUser.email,
      "password": loggedInUser.password,
      "accID": id,
    };

    axios.post(`${backendURL}/api/accounts/delete`, { ...data })
      .catch((err) => console.log(err))
      .then(() => {
        setMessage("");
        const dataToSet = {
          "uid": loggedInUser.uid,
          "roomID": loggedInUser.roomID,
          "name": loggedInUser.name,
          "username": loggedInUser.username,
          "email": loggedInUser.email,
          "password": loggedInUser.password,

          "accounts": loggedInUser.accounts.filter((account) =>
            account.accID !== id
          ),
        };
        setLoggedInUser(dataToSet);
        cancel();
      }).then(
        ws.send(
          JSON.stringify(
            {
              email: data.email,
              password: data.password,
              roomID: loggedInUser.roomID,
              type: "change",
            },
          ),
        ),
      );
  };

  const gen = () => {
    const length = 20;
    const chars = "abcdefghijklmnopqrstuvwxyz";
    const nums = "0123456789";
    // eslint-disable-next-line
    const symbols = "!#$%&*_+|}{[]\?></=";

    let pass = "";
    let character = "";

    while (pass.length < length) {
      let pos1 = Math.floor(chars.length * Math.random() * Math.random());
      let pos2 = Math.floor(nums.length * Math.random() * Math.random());
      let pos3 = Math.floor(symbols.length * Math.random() * Math.random());

      let hold = chars[pos1];
      hold = Math.random() > 0.65 ? hold.toUpperCase() : hold;

      character += hold;
      character += nums[pos2];

      if (Math.random() > 0.55) {
        character += symbols[pos3];
      }

      pass = character;
    }

    pass = pass.split("").sort(() => 0.5 - Math.random()).join("");
    setGenPass(pass);
  };

  const cancel = () => {
    setView("l");

    a_setName("");
    a_setCategory("");

    u_setName("");
    u_setUsername("");
    u_setEmail("");
    u_setPassword("");
    u_setLink("");

    setGenPass("");
  };

  const sortedAccounts = loggedInUser.accounts.length > 0
    ? loggedInUser.accounts.sort((a, b) =>
      (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
    )
    : [];

  const list = (
    <div
      className="sm:w-3/5 w-5/6 flex flex-col text-center sm:justify-start justify-center items-center mx-auto mb-4"
    >
      {sortedAccounts.map((item) => (
        createAccount(
          item.name,
          item.category,
          item.fields[item.fields.length - 1].value,
          item.accID,
          [
            item.fields.map((field) => (
              { "field": field.name, "value": field.value, "id": field._id }
            )),
          ],
        )
      ))}
    </div>
  );

  const add = (submitFunc) => (
    <div
      className="sm:w-3/5 w-5/6 flex flex-col text-center sm:justify-start justify-center items-center mx-auto sm:mb-0 mb-4"
    >
      <div
        className="sm:w-11/12 w-full sm:mt-0 mt-4 flex flex-col text-left sm:justify-center sm:h-screen"
      >
        <div className="rounded-lg bg-gray-700 border-2 border-blue-300">
          <div
            className="w-full p-2 flex flex-col text-left justify-left border-b-2 border-blue-300"
          >
            <p
              className="font-karla sm:text-2xl text-lg font-bold text-blue-200 tracking-wider uppercase w-full text-center"
            >
              Account Details
            </p>
            {makeInputField("Name", a_Name, a_setName, "e.g Facebook")}
            {makeInputField(
              "Category",
              a_Category,
              a_setCategory,
              "e.g Social Media",
            )}
          </div>
          <div className="w-full p-2 flex flex-col text-left justify-left">
            <p
              className="font-karla sm:text-2xl text-lg font-bold text-blue-200 tracking-wider uppercase w-full text-center"
            >
              User Details
            </p>
            {makeInputField("Name", u_Name, u_setName, "e.g John Doe")}
            {makeInputField(
              "Username",
              u_Username,
              u_setUsername,
              "e.g johndoe",
            )}
            {makeInputField(
              "Email",
              u_Email,
              u_setEmail,
              "e.g john.doe@example.com",
            )}
            {makeInputField(
              "Password",
              u_Password,
              u_setPassword,
              "e.g J0hN*D0e",
            )}
            {makeInputField(
              "Profile Link",
              u_Link,
              u_setLink,
              "e.g https://www.facebook.com/john.doe",
            )}
          </div>
          <div
            className="flex sm:flex-row flex-col w-full justify-around items-center sm:mb-4 mb-2 mt-2"
          >
            <button
              className={`rounded-lg py-3 sm:w-1/3 w-5/6 mx-auto bg-blue-300 ${
                (!a_Name || !a_Category)
                  ? "opacity-50"
                  : "hover:bg-blue-400 focus:bg-blue-400"
              } font-nanum tracking-wider uppercase font-extrabold sm:text-md text-xs text-blue-900 my-1`}
              onClick={(!a_Name || !a_Category) ? null : submitFunc}
            >
              Submit
            </button>
            <button
              className="rounded-lg py-3 sm:w-1/3 w-5/6 mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 font-nanum tracking-wider uppercase font-extrabold sm:text-md text-xs text-blue-900 my-1"
              onClick={() => cancel()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const loading = (
    <div
      className="w-screen sm:h-screen flex flex-col text-center justify-center items-center sm:my-0 my-4"
    >
      <div
        className="font-bold tracking-wide font-mono sm:text-4xl text-xl text-yellow-300 flex flex-col"
      >
        {message}
      </div>
    </div>
  );

  const base = (
    <div
      className="w-screen flex sm:flex-row flex-col text-center justify-around"
    >
      <div
        className="sm:w-1/3 w-5/6 sm:h-screen bg-custom-300 flex flex-col text-center justify-center items-center mx-auto py-4 sm:mt-0 mt-4 sm:fixed sm:left-0 sm:ml-12"
      >
        <p
          className="font-karla sm:text-4xl text-2xl font-bold text-custom-200 tracking-wider uppercase border-b-2 border-custom-200"
        >
          Accounts
        </p>
        {view === "l"
          ? <button
            className="rounded-lg p-2 sm:w-3/5 w-5/6 mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 font-nanum tracking-wider uppercase font-extrabold sm:text-lg text-sm mt-4 text-blue-900"
            onClick={() => setView("a")}
          >
            Add
          </button>
          : <div className="w-full">
            <input
              className="font-nunito p-2 rounded-lg px-auto text-center py-1 w-4/5 border-blue-300 bg-blue-100 sm:text-xl text-md text-blue-900 mt-4 border-2 border-blue-100"
              value={genPass}
              readOnly
              onClick={(e) => {
                console.log("Clicked!");
                e.target.select();
                document.execCommand("copy");
              }}
            />
            <button
              className="rounded-lg p-1 w-2/5 mx-auto bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-400 text-center font-nanum tracking-wider uppercase font-extrabold sm:text-md text-xs mt-4 text-yellow-900"
              onClick={() => gen()}
            >
              Gen
            </button>
          </div>}
      </div>
      <div
        className="sm:w-1/3 sm:h-screen flex flex-col text-center justify-center items-center mx-auto"
      >
      </div>
      {message !== ""
        ? loading
        : view === "l"
        ? list
        : view === "e"
        ? add(() => submitEdit())
        : add(() => submitAdd())}
    </div>
  );

  return base;
}
