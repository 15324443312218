import React, { useContext } from "react";
import axios from "axios";
import Files from "react-files";

import { localContext } from "../Context";
import { useHistory } from "react-router-dom";

export default function Backup() {
  const { loggedInUser, setLoggedInUser, backendURL, ws } = useContext(
    localContext,
  );

  const history = useHistory();

  if (!loggedInUser.username) {
    let localMessage =
      "You need to register or log in first to view the dashboard.";
    setTimeout(() => {
      history.push("/");
    }, 1000);

    return (
      <div
        className="w-full h-screen my-auto flex sm:flex-row flex-col items-center justify-around text-yellow-300 tracking-wide font-nunito sm:text-2xl text-lg font-bold mx-2 text-center"
      >
        {localMessage}
      </div>
    );
  }

  const today = new Date();
  const timestamp = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" +
    today.getDate() + "-" + today.getHours() + "." + today.getMinutes() + "." +
    today.getSeconds();
  const dataStr = "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(loggedInUser.accounts));

  const fileReader = new FileReader();
  fileReader.onload = (e) => {
    let fileData = {
      "name": loggedInUser.name,
      "username": loggedInUser.username,
      "email": loggedInUser.email,
      "password": loggedInUser.password,

      "accounts": JSON.parse(e.target.result),
    };

    axios.put(`${backendURL}/api/accounts/restore`, { ...fileData })
      .catch((err) => console.log(err))
      .then(alert("Passwords Restored!"))
      .then(
        setTimeout(() =>
          ws.send(
            JSON.stringify(
              {
                email: fileData.email,
                password: fileData.password,
                roomID: loggedInUser.roomID,
                type: "change",
              },
            ),
          )
        ),
        1500,
      );

    let newAccounts = [];
    fileData.accounts.forEach((item) =>
      loggedInUser.accounts.every((acc) => acc.accID !== item.accID)
        ? newAccounts.push(item)
        : null
    );

    setLoggedInUser({
      "uid": loggedInUser.uid,
      "roomID": loggedInUser.roomID,
      "name": loggedInUser.name,
      "username": loggedInUser.username,
      "email": loggedInUser.email,
      "password": loggedInUser.password,
      "accounts": [
        ...loggedInUser.accounts,
        ...newAccounts,
      ],
    });
  };

  return (
    <div className="flex flex-col w-screen justify-center items-center">
      <p
        className="sm:text-5xl text-4xl font-karla font-bold tracking-wider my-4 w-full uppercase text-custom-200 text-center"
      >
        Backup / Restore Passwords
      </p>
      <a
        href={dataStr}
        download={`kinesis_pass_data-${timestamp}.json`}
        className="bg-custom-200 sm:w-1/4 w-4/5 text-center py-4 tracking-wide rounded-lg my-2 hover:bg-blue-400 focus:bg-blue-400 font-nanum font-bold sm:text-xl text-lg text-teal-900"
      >
        Backup
      </a>
      <button
        className="bg-custom-200 sm:w-1/4 w-4/5 text-center py-4 tracking-wide rounded-lg my-2 hover:bg-blue-400 focus:bg-blue-400 font-nanum font-bold sm:text-xl text-lg text-teal-900"
      >
        <Files
          className="files-dropzone"
          onChange={(file) => {
            fileReader.readAsText(file[0]);
          }}
          onError={(err) => console.log(err)}
          accepts={[".json"]}
          maxFiles={1}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          Restore
        </Files>
      </button>
    </div>
  );
}
