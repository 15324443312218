import React, { useState } from "react";

const localContext = React.createContext();

function Context(props) {
  const [loggedInUser, setLoggedInUser] = useState({});

  // eslint-disable-next-line
  const [prefs, setPrefs] = useState({
    "allow-websockets": false,
    "allow-localstorage": false,
  });

  const backendURL = "https://auth.kinesis.games";
  // const backendURL = "http://localhost:13000";
  const socketURL = "wss://ws.kinesis.games/pass";
  // const socketURL = "ws://localhost:15000/pass";

  const ws = new WebSocket(socketURL);

  return (
    <localContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
        socketURL,
        backendURL,
        ws,
      }}
    >
      {props.children}
    </localContext.Provider>
  );
}

export { localContext, Context };
