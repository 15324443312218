import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
//import jwt from "jwt-decode";

import { localContext } from "../Context";

function infoBox(label, oldVal, newVal, setNewVal, validity, click, error) {
  return (
    <div
      className={`w-5/6 mx-auto bg-custom-400 flex sm:flex-row flex-wrap items-center justify-between py-4 my-2`}
    >
      <div
        className={`sm:w-1/6 w-4/5 sm:my-0 my-2 sm:mx-4 mx-auto font-nanum font-bold tracking-widest uppercase sm:text-xl text-lg py-2 text-gray-300 sm:text-left text-center`}
      >
        {label}
      </div>
      <input
        className={`sm:w-1/4 w-4/5 sm:my-0 my-2 sm:mx-2 mx-auto bg-gray-500 py-2 rounded-lg px-2 border-4 border-red-400 font-nunito sm:placeholder-text-sm sm:text-sm`}
        value={oldVal}
        disabled
      />
      {click
        ? <input
          className="sm:w-1/4 w-4/5 sm:my-0 my-2 sm:mx-2 mx-auto bg-gray-400 py-2 rounded-lg px-2 font-nunito border-4 focus:border-blue-500 placeholder-gray-700 sm:placeholder-text-sm sm:text-sm"
          placeholder={`Enter updated ${label.toLowerCase()}...`}
          value={newVal}
          onChange={(e) => setNewVal(e.target.value)}
        />
        : <input
          className="sm:w-1/4 w-4/5 my-0 sm:mx-2 mx-auto sm:py-2 sm:px-2 font-nunito border-4 sm:placeholder-text-sm sm:text-sm opacity-0 placholder-opacity-0"
          disabled
        />}
      {click
        ? <button
          className={`sm:w-1/6 w-4/5 sm:my-auto my-2 sm:mx-4 mx-auto bg-blue-300 rounded-lg py-2 uppercase mono tracking-wide ${
            validity ? `hover:bg-blue-400 focus:bg-blue-400` : `opacity-50`
          } font-bold text-blue-900`}
          onClick={click}
          disabled={!validity}
        >
          Change
        </button>
        : <button
          className={`sm:w-1/6 w-4/5 sm:my-auto sm:mx-4 mx-auto sm:py-2 uppercase mono tracking-wide opacity-0 font-bold`}
          disabled
        >
          Change
        </button>}
      <ul className="underline w-full text-red-300">
        {error
          ? <li>
            <div
              className="w-4/5 mt-2 mx-auto text-center mono tracking-wide sm:text-md text-sm py-2 text-red-300"
            >
              {error}
            </div>
          </li>
          : null}
      </ul>
    </div>
  );
}

export default function Profile() {
  const { loggedInUser, setLoggedInUser, backendURL, ws } = useContext(
    localContext,
  );
  const history = useHistory();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const [errorMessages, setErrorMessages] = useState(["", "", "", "", ""]);
  const [isDataValid, setIsDataValid] = useState(
    [false, false, false, false, false],
  );

  if (!loggedInUser.username) {
    const localMessage =
      "You need to register or log in first to view/modify your profile.";
    setTimeout(() => {
      history.push("/");
    }, 1000);

    return (
      <div
        className="w-full h-screen my-auto flex sm:flex-row flex-col items-center justify-around text-yellow-300 tracking-wide font-nunito sm:text-2xl text-lg font-bold mx-2 text-center"
      >
        {localMessage}
      </div>
    );
  }

  function nameSetter(value) {
    setName(value);
    const check = /^[a-zA-Z ]+$/.test(value);
    updateDataValidity(check, 0);
    updateErrorMessages(check ? "" : value ? "Invalid name entered" : "", 0);
  }

  function usernameSetter(value) {
    setUsername(value);
    const check = /^[a-zA-Z0-9]+$/.test(value);
    //const check2 = data.find(item => item.username.toLowerCase() === value.toLowerCase() && item.username.toLowerCase() !== value.toLowerCase()) === undefined;
    const check2 = true;

    updateDataValidity((check && check2), 1);

    if (value === "") {
      updateErrorMessages("", 1);
    } else {
      if (!check) {
        updateErrorMessages("Invalid username entered", 1);
        /*} else if (!check2) {
                    updateErrorMessages("Username already taken", 1);*/
      } else {
        updateErrorMessages("", 1);
      }
    }
  }

  function oldPasswordSetter(value) {
    setOldPassword(value);
    const check = (value.length >= 8);
    // eslint-disable-next-line
    const check2 = value === loggedInUser.password;
    // eslint-disable-next-line
    const lowercase = new RegExp("^(?=.*[a-z])");
    const lowercaseCheck = lowercase.test(value);
    // eslint-disable-next-line
    const uppercase = new RegExp("^(?=.*[A-Z])");
    const uppercaseCheck = uppercase.test(value);
    // eslint-disable-next-line
    const number = new RegExp("^(?=.*[0-9])");
    const numberCheck = number.test(value);
    // eslint-disable-next-line
    const symbol = new RegExp("^(?=.*[!@#\$%\^&\*])");
    const symbolCheck = symbol.test(value);

    //const newCheck = value === passwordCheck;
    //updateDataValidity(newCheck, 4);
    //updateErrorMessages(newCheck ? "" : "Passwords don't match", 4);

    updateDataValidity(
      (check && check2 && lowercaseCheck && uppercaseCheck && numberCheck &&
        symbolCheck),
      2,
    );

    if (value === "") {
      updateErrorMessages("", 2);
    } else {
      if (!check) {
        updateErrorMessages("Password should be longer than 8 characters", 2);
      } else if (!lowercaseCheck) {
        updateErrorMessages(
          "Password should contain at least 1 lowercase alphabetical character",
          2,
        );
      } else if (!uppercaseCheck) {
        updateErrorMessages(
          "Password should contain at least 1 uppercase alphabetical character",
          2,
        );
      } else if (!numberCheck) {
        updateErrorMessages(
          "Password should contain at least 1 numerical character",
          2,
        );
      } else if (!symbolCheck) {
        updateErrorMessages("Password should contain at least 1 symbol", 2);
      } else if (!check2) {
        updateErrorMessages(
          "Password should correspond to the current password",
          2,
        );
      } else {
        updateErrorMessages("", 2);
      }
    }
  }

  function passwordSetter(value) {
    setPassword(value);
    const check = (value.length >= 8);
    // eslint-disable-next-line
    const lowercase = new RegExp("^(?=.*[a-z])");
    const lowercaseCheck = lowercase.test(value);
    // eslint-disable-next-line
    const uppercase = new RegExp("^(?=.*[A-Z])");
    const uppercaseCheck = uppercase.test(value);
    // eslint-disable-next-line
    const number = new RegExp("^(?=.*[0-9])");
    const numberCheck = number.test(value);
    // eslint-disable-next-line
    const symbol = new RegExp("^(?=.*[!@#\$%\^&\*])");
    const symbolCheck = symbol.test(value);

    //const newCheck = value === passwordCheck;
    //updateDataValidity(newCheck, 4);
    //updateErrorMessages(newCheck ? "" : "Passwords don't match", 4);

    updateDataValidity(
      (check && lowercaseCheck && uppercaseCheck && numberCheck && symbolCheck),
      3,
    );

    if (value === "") {
      updateErrorMessages("", 3);
    } else {
      if (!check) {
        updateErrorMessages(
          "New password should be longer than 8 characters",
          3,
        );
      } else if (!lowercaseCheck) {
        updateErrorMessages(
          "New password should contain at least 1 lowercase alphabetical character",
          3,
        );
      } else if (!uppercaseCheck) {
        updateErrorMessages(
          "New password should contain at least 1 uppercase alphabetical character",
          3,
        );
      } else if (!numberCheck) {
        updateErrorMessages(
          "New password should contain at least 1 numerical character",
          3,
        );
      } else if (!symbolCheck) {
        updateErrorMessages("New password should contain at least 1 symbol", 3);
      } else {
        updateErrorMessages("", 3);
      }
    }

    //const newCheck = value === passwordCheck;
    //updateDataValidity(newCheck, 4);
    //updateErrorMessages(newCheck ? "" : value ? "Passwords don't match" : "", 4);
  }

  function passwordCheckSetter(value) {
    if (value) setPasswordCheck(value);
    const check = password === value;
    updateDataValidity(check, 4);
    updateErrorMessages(check ? "" : value ? "Passwords don't match" : "", 4);
  }

  function updateDataValidity(validity, i) {
    const newData = isDataValid.map((item, index) => {
      if (index === i) {
        return validity;
      } else {
        return item;
      }
    });
    setIsDataValid(newData);
  }

  function updateErrorMessages(message, i) {
    const newData = errorMessages.map((item, index) => {
      if (index === i) {
        return message;
      } else {
        return item;
      }
    });
    setErrorMessages(newData);
  }

  function submitName() {
    const data = {
      name: name,
      username: loggedInUser.username,
      email: loggedInUser.email,
      old_password: loggedInUser.password,
      password: loggedInUser.password,
    };

    axios.put(`${backendURL}/api/users/update/`, { ...data })
      .catch((err) => console.log(err))
      .then(() => {
        setLoggedInUser(
          {
            ...data,
            uid: loggedInUser.uid,
            roomID: loggedInUser.roomID,
            accounts: loggedInUser.accounts,
          },
        );
        ws.send(
          JSON.stringify(
            {
              email: data.email,
              password: data.password,
              roomID: loggedInUser.roomID,
              type: "change",
            },
          ),
        );
        setName("");
        nameSetter("");
      });
  }

  function submitUsername() {
    const data = {
      name: loggedInUser.name,
      username: username,
      email: loggedInUser.email,
      old_password: loggedInUser.password,
      password: loggedInUser.password,
    };

    let error;

    axios.put(`${backendURL}/api/users/update/`, { ...data })
      .then((res) => null)
      .catch((err) => {
        console.log(err);
        error = err;
      })
      .then(() => {
        if (error && error.toString().includes("401")) {
          alert("Username already taken!");
        } else {
          setLoggedInUser(
            {
              ...data,
              uid: loggedInUser.uid,
              roomID: loggedInUser.roomID,
              accounts: loggedInUser.accounts,
            },
          );
          ws.send(
            JSON.stringify(
              {
                email: data.email,
                password: data.password,
                roomID: loggedInUser.roomID,
                type: "change",
              },
            ),
          );
        }
        setUsername("");
        usernameSetter("");
      });
  }

  function submitPassword() {
    const data = {
      name: loggedInUser.name,
      username: loggedInUser.username,
      email: loggedInUser.email,
      old_password: oldPassword,
      password: passwordCheck,
    };

    axios.put(`${backendURL}/api/users/update/`, { ...data })
      .catch((err) => console.log(err))
      .then(() => {
        setLoggedInUser(
          {
            ...data,
            uid: loggedInUser.uid,
            roomID: loggedInUser.roomID,
            accounts: loggedInUser.accounts,
          },
        );
        ws.send(
          JSON.stringify(
            {
              email: data.email,
              password: data.password,
              roomID: loggedInUser.roomID,
              type: "change",
            },
          ),
        );
        setOldPassword("");
        oldPasswordSetter("");
        setPassword("");
        passwordSetter("");
        setPasswordCheck("");
        passwordCheckSetter("");
      });
  }

  function deleteAccount() {
    const postData = {
      email: loggedInUser.email,
      password: loggedInUser.password,
    };

    if (window.confirm("Are you sure that you want to delete your account?")) {
      axios.post(`${backendURL}/api/users/delete/`, { ...postData })
        .catch((err) => console.log(err))
        .then(() => {
          ws.send(
            JSON.stringify(
              {
                email: loggedInUser.email,
                password: postData.password,
                roomID: loggedInUser.roomID,
                type: "delete",
              },
            ),
          );
          history.push("/");
          localStorage.clear();
        });
    }
  }

  return (
    <div className="flex flex-col w-screen">
      <p
        className="font-karla sm:text-5xl text-4xl font-bold text-custom-200 tracking-wider uppercase w-full text-center my-4"
      >
        {loggedInUser.username}'s Profile
      </p>
      {infoBox(
        "Name",
        loggedInUser.name,
        name,
        nameSetter,
        isDataValid[0],
        () => submitName(),
        errorMessages[0],
      )}
      {infoBox(
        "Username",
        loggedInUser.username,
        username,
        usernameSetter,
        isDataValid[1],
        () => submitUsername(),
        errorMessages[1],
      )}
      {infoBox("Email Address", loggedInUser.email)}
      <div
        className={`w-5/6 mx-auto bg-custom-400 flex sm:flex-row flex-wrap items-center justify-between py-4 my-2`}
      >
        <div
          className="sm:w-1/6 w-4/5 sm:my-0 my-2 sm:mx-4 mx-auto font-nanum font-bold tracking-widest uppercase sm:text-xl text-lg py-2 text-gray-300 sm:text-left text-center"
        >
          Password
        </div>
        <input
          type="password"
          className="sm:w-1/6 w-4/5 sm:my-0 my-2 sm:mr-2 sm:ml-8 mx-auto bg-gray-400 py-2 rounded-lg px-2 font-nunito border-4 focus:border-blue-500 placeholder-gray-700 sm:placeholder-text-sm sm:text-sm"
          placeholder="Enter current password..."
          value={oldPassword}
          onChange={(e) => oldPasswordSetter(e.target.value)}
        />
        <input
          type="password"
          className="sm:w-1/6 w-4/5 sm:my-0 my-2 sm:mr-2 sm:ml-2 mx-auto bg-gray-400 py-2 rounded-lg px-2 font-nunito border-4 focus:border-blue-500 placeholder-gray-700 sm:placeholder-text-sm sm:text-sm"
          placeholder={`Enter new password...`}
          value={password}
          onChange={(e) => passwordSetter(e.target.value)}
        />
        <input
          type="password"
          className="sm:w-1/6 w-4/5 sm:my-0 my-2 sm:mr-2 sm:ml-2 mx-auto bg-gray-400 py-2 rounded-lg px-2 font-nunito border-4 focus:border-blue-500 placeholder-gray-700 sm:placeholder-text-sm sm:text-sm"
          placeholder={`Enter new password again...`}
          value={passwordCheck}
          onChange={(e) => passwordCheckSetter(e.target.value)}
        />
        <button
          className={`sm:w-1/6 w-4/5 sm:my-0 sm:my-auto my-2 sm:mx-4 mx-auto bg-blue-300 rounded-lg py-2 uppercase mono tracking-wide ${
            isDataValid[2] && isDataValid[3] && isDataValid[4]
              ? `hover:bg-blue-400 focus:bg-blue-400`
              : `opacity-50`
          } font-bold text-blue-900`}
          onClick={() => submitPassword()}
          disabled={!(isDataValid[2] && isDataValid[3] && isDataValid[4])}
        >
          Change
        </button>
        <ul
          className="underline sm:w-2/3 w-full mx-auto text-red-300 text-center mt-2"
        >
          {errorMessages[2]
            ? <li>
              <div
                className="w-4/5 mx-auto text-center mono tracking-wide sm:text-md text-sm py-1 text-red-300"
              >
                {errorMessages[2]}
              </div>
            </li>
            : null}
          {errorMessages[3]
            ? <li>
              <div
                className="w-4/5 mx-auto text-center mono tracking-wide sm:text-md text-sm py-1 text-red-300"
              >
                {errorMessages[3]}
              </div>
            </li>
            : null}
          {errorMessages[4]
            ? <li>
              <div
                className="w-4/5 mx-auto text-center mono tracking-wide sm:text-md text-sm py-1 text-red-300"
              >
                {errorMessages[4]}
              </div>
            </li>
            : null}
        </ul>
      </div>
      <div
        className={`w-5/6 mx-auto bg-custom-400 flex items-center justify-center py-4 my-2`}
      >
        <button
          className={`sm:w-1/6 w-4/5 sm:my-0 sm:my-auto my-2 sm:mx-4 mx-auto bg-red-300 rounded-lg sm:py-4 py-2 uppercase mono tracking-wide hover:bg-red-400 focus:bg-red-400 font-bold text-red-900`}
          onClick={() => deleteAccount()}
        >
          Delete Account
        </button>
      </div>
    </div>
  );
}
