import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { localContext } from "../Context";

function templateButton(remix, text, click) {
  return (
    <button
      className="bg-custom-200 hover:bg-green-400 focus:bg-green-400 shadow-xl sm:h-64 h-40 sm:w-1/4 w-4/5 rounded-lg justify-center items-center flex flex-col tracking-wider font-nanum font-extrabold sm:text-xl text-lg text-teal-900 sm:my-0 my-4"
      onClick={click}
    >
      <div className={`ri-${remix}-line rounded-full sm:text-4xl text-2xl`}>
      </div>
      {text}
    </button>
  );
}

export default function Dashboard() {
  const { loggedInUser, setLoggedInUser } = useContext(localContext);
  const history = useHistory();

  if (!loggedInUser.username) {
    let localMessage =
      "You need to register or log in first to view the dashboard.";
    setTimeout(() => {
      history.push("/");
    }, 1000);

    return (
      <div
        className="w-full h-screen my-auto flex sm:flex-row flex-col items-center justify-around text-yellow-300 tracking-wide font-nunito sm:text-2xl text-lg font-bold mx-2 text-center"
      >
        {localMessage}
      </div>
    );
  }

  function logOut() {
    setLoggedInUser({});
    history.push("/");
    localStorage.clear();
  }

  const title = (
    <div
      className="font-karla sm:text-5xl text-4xl font-bold text-custom-200 tracking-wider uppercase w-full text-center my-4"
    >
      {loggedInUser.username}'s Dashboard
    </div>
  );

  return (
    <div className="w-full">
      {title}
      <div
        className="w-full sm:my-8 flex sm:flex-row flex-col items-center justify-around"
      >
        {templateButton(
          "profile",
          "Modify Profile",
          () => history.push("/profile"),
        )}
        {templateButton(
          "key-2",
          "View, Add and Edit Passwords",
          () => history.push("/passwords"),
        )}
        {templateButton(
          "save-3",
          "Backup / Restore Passwords",
          () => history.push("/backup"),
        )}
      </div>
      <div
        className="w-full sm:my-8 flex sm:flex-row flex-col items-center justify-around"
      >
        {templateButton("logout-circle", "Log out", () => logOut())}
      </div>
    </div>
  );
}
