import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import jwt from "jwt-decode";

import { localContext } from "../Context";

export default function LoginScreen() {
  const { setLoggedInUser, backendURL, ws } = useContext(localContext);
  const history = useHistory();

  const [isRegistering, setIsRegistering] = useState(true);

  const [logUsername, setLogUsername] = useState("");
  const [logPassword, setLogPassword] = useState("");

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const [message, setMessage] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [isVerifying, setisVerifying] = useState(false);

  const [isDataValid, setIsDataValid] = useState(
    [false, false, false, false, false],
  );
  const [errorMessages, setErrorMessages] = useState(["", "", "", "", ""]);

  const usernameRef = useRef(null);

  function nameSetter(event) {
    setName(event.target.value);
    const check = /^[a-zA-Z ]+$/.test(event.target.value);
    updateDataValidity(check, 0);
    updateErrorMessages(
      check ? "" : event.target.value ? "Invalid name entered" : "",
      0,
    );
  }

  function userNameSetter(event) {
    setUsername(event.target.value);
    const check = /^[a-zA-Z0-9]+$/.test(event.target.value);
    //const check2 = data.find(item => item.username.toLowerCase() === event.target.value.toLowerCase()) === undefined;
    const check2 = true;

    updateDataValidity((check && check2), 1);

    if (!event.target.value) {
      updateErrorMessages("", 1);
      return;
    }

    if (!check) {
      updateErrorMessages("Invalid username entered", 1);
      /*} else if (!check2) {
                updateErrorMessages("Username already taken", 1);*/
    } else {
      updateErrorMessages("", 1);
    }
  }

  function emailSetter(event) {
    setEmail(event.target.value);
    const check = // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        .test(event.target.value);
    //const check2 = data.find(item => item.email.toString().toLowerCase() === event.target.value.toString().toLowerCase());
    const check2 = false;

    updateDataValidity((check && !check2), 2);

    if (!event.target.value) {
      updateErrorMessages("", 2);
      return;
    }

    if (!check) {
      updateErrorMessages("Invalid email address entered", 2);
      /*} else if (check2) {
                updateErrorMessages("Email address already registered", 2);*/
    } else {
      updateErrorMessages("", 2);
    }
  }

  function passwordSetter(event) {
    setPassword(event.target.value);
    const check = (event.target.value.length >= 8);
    // eslint-disable-next-line
    const lowercase = new RegExp("^(?=.*[a-z])");
    const lowercaseCheck = lowercase.test(event.target.value);
    // eslint-disable-next-line
    const uppercase = new RegExp("^(?=.*[A-Z])");
    const uppercaseCheck = uppercase.test(event.target.value);
    // eslint-disable-next-line
    const number = new RegExp("^(?=.*[0-9])");
    const numberCheck = number.test(event.target.value);
    // eslint-disable-next-line
    const symbol = new RegExp("^(?=.*[!@#\$%\^&\*])");
    const symbolCheck = symbol.test(event.target.value);

    updateDataValidity(
      (check && lowercaseCheck && uppercaseCheck && numberCheck && symbolCheck),
      3,
    );

    //const newCheck = event.target.value === passwordCheck;
    //updateDataValidity(newCheck, 4);
    //updateErrorMessages(newCheck ? "" : "Passwords don't match", 4);

    //updateDataValidity(event.target.value === passwordCheck, 4);
    //updateErrorMessages(event.target.value === passwordCheck ? "" : passwordCheck ? "Passwords don't match" : "", 4);

    if (!event.target.value) {
      updateErrorMessages("", 3);
      return;
    }

    if (!check) {
      updateErrorMessages("Password should be longer than 8 characters", 3);
    } else if (!lowercaseCheck) {
      updateErrorMessages(
        "Password should contain at least 1 lowercase alphabetical character",
        3,
      );
    } else if (!uppercaseCheck) {
      updateErrorMessages(
        "Password should contain at least 1 uppercase alphabetical character",
        3,
      );
    } else if (!numberCheck) {
      updateErrorMessages(
        "Password should contain at least 1 numerical character",
        3,
      );
    } else if (!symbolCheck) {
      updateErrorMessages("Password should contain at least 1 symbol", 3);
    } else {
      updateErrorMessages("", 3);
    }
  }

  function passwordCheckSetter(event) {
    setPasswordCheck(event.target.value);
    const check = password === event.target.value;
    updateDataValidity(check, 4);
    updateErrorMessages(
      check ? "" : event.target.value ? "Passwords don't match" : "",
      4,
    );
  }

  function updateDataValidity(validity, i) {
    const newData = isDataValid.map((item, index) => {
      if (index === i) {
        return validity;
      } else {
        return item;
      }
    });
    setIsDataValid(newData);
  }

  function updateErrorMessages(message, i) {
    const newData = errorMessages.map((item, index) => {
      if (index === i) {
        return message;
      } else {
        return item;
      }
    });
    setErrorMessages(newData);
  }

  function userNameSetter_log(event) {
    setLogUsername(event.target.value);
    //setFormOutput("");
  }

  function passwordSetter_log(event) {
    setLogPassword(event.target.value);
    //setFormOutput("");
  }

  function validateForm(event) {
    event.preventDefault();

    setMessage("Validating...");
    setisVerifying(true);

    if (isRegistering) {
      const postData = {
        username: username,
        name: name,
        email: email,
        password: passwordCheck,
      };

      let error = "";
      let responseMsg;

      axios.post(`${backendURL}/api/users/register/`, { ...postData })
        .then((res) => {
          responseMsg = res.data;
        })
        .catch((err) => {
          console.log(err);
          error = err;
        })
        .then(() => {
          if (error) {
            if (responseMsg && responseMsg.toString().length > 0) {
              setMessage(responseMsg.toString());
            } else {
              if (error.toString().includes("401")) {
                setMessage("Username or Email already taken.");
              } else {
                setMessage("Invalid form data submitted. Please try again.");
              }
            }
            setFormValid(false);
            setIsRegistering(true);
            setisVerifying(false);
          } else {
            setMessage(
              "Form validated! Please check your mails to activate your account.",
            );
            setFormValid(true);
            setIsRegistering(false);
            setisVerifying(false);
          }
        }).then(
          setTimeout(() => {
            setMessage("");

            setName("");
            setUsername("");
            setEmail("");
            setPassword("");
            setPasswordCheck("");

            setIsDataValid([false, false, false, false, false]);
          }, 3000),
        );
    } else {
      const data = {
        username: logUsername,
        password: logPassword,
      };

      let error = "";
      let userDecoded;

      axios.post(`${backendURL}/api/users/login/`, { ...data })
        .then((res) => {
          if (res.status === 200) {
            userDecoded = jwt(res.data.jwt);
          }
        })
        .catch((err) => error = err)
        .then(() => {
          if (error) {
            console.log(error);
            let time = 1500;
            if (error.toString().includes("401")) {
              setMessage(
                "Please check your mails to activate your account first.",
              );
              time = 3000;
            } else {
              setMessage("Invalid credentials entered. Please try again.");
            }
            setFormValid(false);
            setisVerifying(false);
            setTimeout(() => {
              setMessage("");

              setLogUsername("");
              setLogPassword("");

              history.push("/");
            }, time);
          } else {
            setMessage("Authenticated! Logging in...");
            setFormValid(true);
            setisVerifying(false);
            const { uid, roomID, name, username, email, accounts } = JSON.parse(
              userDecoded.iss,
            );
            setLoggedInUser(
              {
                uid,
                roomID,
                name,
                username,
                email,
                password: logPassword,
                accounts,
              },
            );
            setTimeout(() => {
              setMessage("");

              setLogUsername("");
              setLogPassword("");

              ws.send(JSON.stringify({ roomID: roomID, type: "join" }));

              history.push("/dashboard");
            }, 1500);
          }
        });
    }
  }

  const errors = isRegistering
    ? errorMessages.map((error, i) => {
      if (error !== "") {
        return <li key={`li-${i}`} className="sm:text-md text-xs">{error}</li>;
      } else {
        return "";
      }
    })
    : "";

  const success = (
    <div className="w-full">
      <div className="sm:w-4/5 w-11/12 mx-auto justify-between">
        <div
          className="rounded-full sm:w-16 sm:h-16 w-10 h-10 flex text-center justify-center items-center bg-green-300 mx-auto"
        >
          <i
            className="ri-check-line sm:text-4xl text-2xl font-extrabold text-blue-900"
          >
          </i>
        </div>
        <p
          className="w-full text-center sm:text-xl text-lg font-bold text-green-300 mt-4 font-mono"
        >
          {message}
        </p>
      </div>
    </div>
  );

  const loading = (
    <div className="w-full">
      <div className="w-sm:4/5 m w-11/12x-auto justify-between">
        <div
          className="rounded-full sm:w-16 sm:h-16 w-10 h-10 flex text-center justify-center items-center bg-yellow-300 mx-auto"
        >
          <i
            className="ri-refresh-line sm:text-4xl text-2xl font-extrabold text-blue-900"
          >
          </i>
        </div>
        <p
          className="w-full text-center sm:text-xl text-lg font-bold text-yellow-300 mt-4 font-mono"
        >
          {message}
        </p>
      </div>
    </div>
  );

  const failure = (
    <div className="w-full">
      <div className="sm:w-4/5 w-11/12 mx-auto justify-between">
        <div
          className="rounded-full sm:w-16 sm:h-16 w-10 h-10 flex text-center justify-center items-center bg-red-300 mx-auto"
        >
          <i
            className="ri-close-line sm:text-4xl text-2xl font-extrabold text-blue-900"
          >
          </i>
        </div>
        <p
          className="w-full text-center sm:text-xl text-lg font-bold text-red-300 mt-4 font-mono"
        >
          {message}
        </p>
      </div>
    </div>
  );

  const inputClasses =
    "w-4/5 p-2 rounded font-nunito my-2 placeholder-gray-600";
  const submitButtonClasses =
    `font-nanum tracking-wide font-bold uppercase sm:text-2xl text-lg text-custom-300 my-4 bg-blue-300 ${
      isDataValid.every((item) => item)
        ? `hover:bg-blue-400 focus:bg-blue-400`
        : ""
    } w-4/5 py-1 rounded`;

  const registerForm = (
    <form className="w-full" onSubmit={(e) => validateForm(e)}>
      <input
        type="text"
        label="Name"
        placeholder="Enter Name"
        className={inputClasses}
        value={name}
        onChange={(e) => nameSetter(e)}
      />
      <input
        type="text"
        label="Username"
        placeholder="Enter Username"
        className={inputClasses}
        value={username}
        ref={usernameRef}
        onChange={(e) => userNameSetter(e)}
      />
      <input
        type="email"
        label="Email"
        placeholder="Enter Email Address"
        className={inputClasses}
        value={email}
        onChange={(e) => emailSetter(e)}
      />
      <input
        type="password"
        label="Password"
        placeholder="Enter Password"
        className={inputClasses}
        value={password}
        onChange={(e) => passwordSetter(e)}
      />
      <input
        type="password"
        label="Password again"
        placeholder="Enter Password Again"
        className={inputClasses}
        value={passwordCheck}
        onChange={(e) => passwordCheckSetter(e)}
      />
      <button
        className={`${submitButtonClasses} ${
          isDataValid.every((item) => item) ? "opacity-100" : "opacity-50"
        }`}
        disabled={!isDataValid.every((item) => item)}
      >
        Submit
      </button>
    </form>
  );

  const loginForm = (
    <form className="w-full" onSubmit={(e) => validateForm(e)}>
      <input
        type="text"
        label="Username"
        placeholder="Enter Username"
        className={inputClasses}
        value={logUsername}
        onChange={(e) => userNameSetter_log(e)}
      />
      <input
        type="password"
        label="Password"
        placeholder="Enter Password"
        className={inputClasses}
        value={logPassword}
        onChange={(e) => passwordSetter_log(e)}
      />
      <button
        className={`${submitButtonClasses} ${
          logUsername && logPassword ? "opacity-100" : "opacity-50"
        }`}
        disabled={!(logUsername && logPassword)}
      >
        Submit
      </button>
    </form>
  );

  const form = (
    <div className="w-full">
      <div className="flex w-4/5 mx-auto justify-between">
        <button
          className={`font-nanum uppercase sm:text-xl text-md text-custom-300 my-4 ${
            !isRegistering
              ? `bg-blue-300 hover:bg-blue-400 focus:bg-blue-400`
              : `bg-green-300 opacity-75`
          } w-1/2 mr-1 py-2 rounded-l`}
          onClick={() => setIsRegistering((prev) => !prev)}
          disabled={isRegistering}
        >
          Register
        </button>
        <button
          className={`font-nanum uppercase sm:text-xl text-md text-custom-300 my-4 ${
            isRegistering
              ? `bg-blue-300 hover:bg-blue-400 focus:bg-blue-400`
              : `bg-green-300 opacity-75`
          } w-1/2 ml-1 py-2 rounded-r`}
          onClick={() => {
            setIsRegistering((prev) => !prev); /*usernameRef.current.focus();*/
          }}
          disabled={!isRegistering}
        >
          Login
        </button>
      </div>
      {isRegistering ? registerForm : loginForm}
      <div className="w-4/5 text-left mx-auto text-red-300">
        <ul className="list-disc">
          {errors}
        </ul>
      </div>
    </div>
  );

  let output = form;
  if (isVerifying) {
    output = loading;
  } else {
    if (message) output = formValid ? success : failure;
  }

  return (
    <div
      className="w-screen h-screen flex sm:flex-row flex-col sm:justify-around justify-center"
    >
      <div
        className="flex flex-col sm:w-1/2 w-4/5 mx-auto sm:my-0 my-8 sm:h-5/6 py-8 bg-custom-400 text-center items-center justify-center sm:rounded-none rounded-lg"
      >
        <div
          className="font-karla sm:text-6xl text-4xl font-bold text-custom-200 tracking-wider uppercase underline"
        >
          KinesisPass
        </div>
        <div
          className="font-nanum sm:text-lg text-sm text-red-300 tracking-wide pt--2"
        >
          A secure password manager
        </div>
      </div>
      <div
        className="flex flex-col sm:w-1/3 w-4/5 mx-auto sm:my-0 my-8 sm:h-5/6 py-8 bg-custom-400 text-center items-center justify-center sm:rounded-none rounded-lg"
      >
        {output}
      </div>
    </div>
  );
}
