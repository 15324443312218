import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
    return(
        <div className="w-screen h-screen flex flex-col text-center justify-center items-center">
            <div className="font-bold tracking-wide font-mono sm:text-4xl text-xl text-red-300 flex flex-col">
                <i className="ri-close-circle-line text-6xl font-normal"></i>
                The page you tried to view doesn't appear to exist.
                <Link to="/" className="rounded-lg bg-red-300 hover:bg-red-400 focus:bg-red-400 text-custom-500 p-2 sm:w-1/3 w-4/5 mx-auto sm:my-2 my-4 sm:text-2xl text-lg">Go to homepage</Link>
            </div>
        </div>
    );
}
